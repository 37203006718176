import React, { useEffect, useMemo, useCallback } from 'react'
import Button from 'core/elements/button'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import ExternalLink from 'core/components/ExternalLink'
import { kubevirtDocumentationLink, tutorialsLink } from '../../links'
import { makeParamsAllClustersSelector } from 'app/plugins/infrastructure/components/combinedClusters/selectors'
import useListAction from 'core/hooks/useListAction'
import { listClusters } from 'app/plugins/infrastructure/components/clusters/newActions'
import { listImportedClusters } from 'app/plugins/infrastructure/components/importedClusters/new-actions'
import {
  listVirtualMachines,
  listVirtualMachineInstances,
  listLiveMigrations,
} from '../virtual-machines/new-actions'
import { useSelector } from 'react-redux'
import useSelectorWithParams from 'core/hooks/useSelectorWithParams'
import { ClusterSelector } from 'app/plugins/infrastructure/components/clusters/model'
import StatCard from './StatCard'
import useParams from 'core/hooks/useParams'
import { kubevirtAddonsSelector } from 'app/plugins/infrastructure/components/clusters/cluster-addons/selectors'
import { listStorageClasses } from 'k8s/components/storage/storage-classes/new-actions'
import { listPersistentVolumes } from 'k8s/components/storage/persistent-volume/new-actions'
import { listPersistentVolumeClaims } from 'k8s/components/storage/persistent-volume-claims/new-actions'
import { listDataVolumes } from 'k8s/components/storage/data-volumes/new-actions'
import { kubevirtDashboardResourcesSelector } from './selectors'
import SimpleLink from 'core/components/SimpleLink'
import { routes } from 'core/utils/routes'
import useGlobalParams from 'core/hooks/useGlobalParams'
import FontAwesomeIcon from 'core/components/FontAwesomeIcon'
import Text from 'core/elements/Text'
import { kubevirtEventsSelector } from 'app/plugins/kubevirt/components/events/selectors'
import EntityEventsPage from 'k8s/components/common/entity/entity-events-page'
import { listAlerts } from 'k8s/components/alarms/new-actions'
import KubevirtAlarmsList from './KubevirtAlarmsList'
import DropdownButtons from 'core/elements/DropdownButtons'

const useStyles = makeStyles<Theme>((theme) => ({
  topLinks: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  topCards: {
    marginTop: 24,
    display: 'grid',
    gap: 8,
    gridTemplateColumns: '3fr 4fr 6fr',
  },
  properties: {
    display: 'inline-grid',
    gap: 32,
    gridAutoFlow: 'column',
  },
  property: {
    display: 'grid',
    gap: 4,
    padding: 24,
  },
  normalWeight: {
    fontWeight: 'normal',
  },
  middleCards: {
    marginTop: 16,
    display: 'grid',
    gap: 8,
    gridTemplateColumns: '3fr 4fr 6fr',
  },
  bottomCards: {
    marginTop: 16,
    display: 'grid',
    gap: 8,
    gridTemplateColumns: '1fr 1fr',
    alignItems: 'start',
  },
  cardHeader: {
    display: 'flex',
    padding: '16px 32px',
    borderBottom: `1px solid ${theme.components.card.border}`,
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  seeAll: {
    '&:hover': {
      textDecoration: 'none',
    },
    '& > .simple-link-text': {
      display: 'inline-flex',
      gap: 8,
      alignItems: 'center',
    },
  },
}))

type Params = {
  clusterId: string | string[]
}

const defaultParams: Params = {
  clusterId: '',
}

const TopLinks = ({ refreshFn }) => {
  const classes = useStyles()
  const dropdownButtons = [
    {
      label: 'Read Documentation',
      icon: 'arrow-up-right-from-square',
      externalLink: kubevirtDocumentationLink,
    },
    {
      label: 'Watch Tutorials',
      icon: 'arrow-up-right-from-square',
      externalLink: tutorialsLink,
    },
  ]
  return (
    <div className={classes.topLinks}>
      <Button variant="secondary" icon="rotate" onClick={() => refreshFn()}>
        Refresh
      </Button>
      <DropdownButtons buttons={dropdownButtons} />

      {/* <ExternalLink url={kubevirtDocumentationLink}>
        <Button rightIcon="arrow-up-right-from-square" variant="secondary">
          Read Documentation
        </Button>
      </ExternalLink>
      <ExternalLink url={tutorialsLink}>
        <Button rightIcon="arrow-up-right-from-square" variant="secondary">
          Watch Tutorials
        </Button>
      </ExternalLink> */}
    </div>
  )
}

const allClustersParams = {
  orderBy: 'name',
  kubevirtClusters: true,
}

const healthyClustersParams = {
  orderBy: 'name',
  kubevirtClusters: true,
  healthyClusters: true,
  ecoEnabledClusters: true,
}

const defaultParamsAllClusterSelector = makeParamsAllClustersSelector(allClustersParams)
const defaultParamsHealthyClusterSelector = makeParamsAllClustersSelector(healthyClustersParams)

const SeeAllLink = ({ route }) => {
  const classes = useStyles()
  return (
    <SimpleLink textVariant="caption1" src={route} className={classes.seeAll}>
      <span>See all</span>
      <FontAwesomeIcon size="sm">chevron-right</FontAwesomeIcon>
    </SimpleLink>
  )
}

export default function KubevirtDashboard() {
  const classes = useStyles()
  const { params, updateParams } = useParams(defaultParams)
  const { loading: clustersLoading } = useListAction(listClusters)
  const { loading: importedClustersLoading } = useListAction(listImportedClusters)
  const allClusters = useSelector(defaultParamsAllClusterSelector)
  const healthyClusters = useSelector(defaultParamsHealthyClusterSelector)
  const allNodes = useMemo(() => {
    return allClusters.reduce((accum, cluster: ClusterSelector) => {
      const clusterNodes = cluster?.nodes || []
      return [...accum, ...clusterNodes]
    }, [])
  }, [allClusters])

  useEffect(() => {
    // Clusters need to be loaded up before other list actions are made, otherwise,
    // won't be able to get the cluster IDs
    if (!clustersLoading && !importedClustersLoading) {
      const clusterIds = healthyClusters.map((cluster) => cluster.uuid)
      updateParams({ clusterId: clusterIds })
    }
  }, [clustersLoading, importedClustersLoading, healthyClusters, allClusters])

  useEffect(() => {
    handleReload(true, true)
  }, [])

  const { loading: vmsLoading } = useListAction(listVirtualMachines, {
    params,
    requiredParams: ['clusterId'],
  })

  const { loading: vmisLoading } = useListAction(listVirtualMachineInstances, {
    params,
    requiredParams: ['clusterId'],
  })

  const { loading: storageClassesLoading, reload: reloadStorageClasses } = useListAction(
    listStorageClasses,
    {
      params,
      requiredParams: ['clusterId'],
    },
  )

  const { loading: persistentVolumesLoading, reload: reloadPersistentVolumes } = useListAction(
    listPersistentVolumes,
    {
      params,
      requiredParams: ['clusterId'],
    },
  )

  const { loading: pvcsLoading, reload: reloadPvcs } = useListAction(listPersistentVolumeClaims, {
    params,
    requiredParams: ['clusterId'],
  })

  const { loading: dataVolumesLoading, reload: reloadDataVolumes } = useListAction(
    listDataVolumes,
    {
      params,
      requiredParams: ['clusterId'],
      ignoreNamespace: true,
    },
  )

  const { loading: liveMigrationsLoading, reload: reloadLiveMigrations } = useListAction(
    listLiveMigrations,
    {
      params,
      requiredParams: ['clusterId'],
    },
  )

  const { loading: alertsLoading, reload: reloadAlerts } = useListAction(listAlerts, {
    params,
    requiredParams: ['clusterId'],
  })

  const handleReload = (refetch = true, updateLoadingState = true) => {
    // storage classes & pvs reloaded with pvcs
    // reloadStorageClasses(refetch, updateLoadingState)
    // reloadPersistentVolumes(refetch, updateLoadingState)
    reloadPvcs(refetch, updateLoadingState)
    reloadDataVolumes(refetch, updateLoadingState)
    reloadLiveMigrations(refetch, updateLoadingState)
    reloadAlerts(refetch, updateLoadingState)
  }

  const {
    vms,
    runningVms,
    pausedVms,
    stoppedVms,
    migratingVms,
    otherVms,
    storageClasses,
    pvs,
    pvcs,
    dataVolumes,
    liveMigrations,
    alarms,
  } = useSelectorWithParams(kubevirtDashboardResourcesSelector, {
    ...params,
    useGlobalParams: false,
  })

  const eventEntities = useSelectorWithParams(kubevirtEventsSelector, {
    ...params,
    useGlobalParams: false,
  })

  const clusterData = useMemo(() => {
    return [
      {
        label: 'Total',
        value: allClusters?.length,
        loading: clustersLoading || importedClustersLoading,
      },
      {
        label: 'Healthy',
        value: healthyClusters?.length,
        loading: clustersLoading || importedClustersLoading,
      },
      {
        label: 'Unhealthy',
        value: allClusters?.length - healthyClusters?.length,
        loading: clustersLoading || importedClustersLoading,
      },
    ]
  }, [allClusters, healthyClusters, clustersLoading, importedClustersLoading])

  const nodeData = useMemo(() => {
    const numHealthyNodes = allNodes.filter((node) => node.status === 'ok')
    return [
      {
        label: 'Total',
        value: allNodes?.length,
        loading: clustersLoading || importedClustersLoading,
      },
      {
        label: 'Healthy',
        value: numHealthyNodes?.length,
        loading: clustersLoading || importedClustersLoading,
      },
      {
        label: 'Unhealthy',
        value: allNodes?.length - numHealthyNodes?.length,
        loading: clustersLoading || importedClustersLoading,
      },
    ]
  }, [allNodes, clustersLoading, importedClustersLoading])

  const vmData = useMemo(() => {
    return [
      { label: 'Total', value: vms?.length, loading: vmsLoading },
      {
        label: 'Running',
        value: runningVms?.length,
        loading: vmsLoading,
      },
      {
        label: 'Migrating',
        value: migratingVms?.length,
        loading: vmsLoading,
      },
      {
        label: 'Paused',
        value: pausedVms?.length,
        loading: vmsLoading,
      },
      {
        label: 'Stopped',
        value: stoppedVms?.length,
        loading: vmsLoading,
      },
      {
        label: 'Others',
        value: otherVms?.length,
        loading: vmsLoading,
      },
    ]
  }, [vms, runningVms, pausedVms, stoppedVms, migratingVms, otherVms, vmsLoading])

  const storageData = useMemo(() => {
    return [
      {
        label: 'Classes',
        value: storageClasses?.length,
        loading: storageClassesLoading,
        link: routes.kubevirtStorage.storageClasses.list.path(),
      },
      {
        label: 'PV',
        value: pvs?.length,
        loading: persistentVolumesLoading,
        link: routes.kubevirtStorage.persistentVolumes.list.path(),
      },
      {
        label: 'PVC',
        value: pvcs?.length,
        loading: pvcsLoading,
        link: routes.kubevirtStorage.persistentVolumeClaims.list.path(),
      },
      {
        label: 'Data Volume',
        value: dataVolumes?.length,
        loading: dataVolumesLoading,
        link: routes.kubevirtStorage.dataVolumes.list.path(),
      },
    ]
  }, [
    storageClasses,
    pvs,
    pvcs,
    dataVolumes,
    storageClassesLoading,
    persistentVolumesLoading,
    pvcsLoading,
    dataVolumesLoading,
  ])

  const kubevirtAddons = useSelector(kubevirtAddonsSelector)

  const kubevirtAddonData = useMemo(() => {
    return [
      {
        label: 'Healthy',
        value: kubevirtAddons?.filter((addon) => addon?.healthy)?.length || 0,
        loading: clustersLoading,
      },
      {
        label: 'Installing',
        value: kubevirtAddons?.filter((addon) => addon?.phase === 'Installing')?.length || 0,
        loading: clustersLoading,
      },
      {
        label: 'Unhealthy',
        value:
          kubevirtAddons?.filter((addon) =>
            ['InstallAddonError', 'UninstallAddonError', 'Failed'].includes(addon?.phase),
          )?.length || 0,
        loading: clustersLoading,
      },
    ]
  }, [kubevirtAddons, clustersLoading])

  const liveMigrationData = useMemo(() => {
    return [
      {
        label: 'Active',
        value:
          liveMigrations?.filter((lm) =>
            ['Scheduling', 'Scheduled', 'PreparingTarget', 'TargetReady', 'Running'].includes(
              lm?.status?.phase,
            ),
          )?.length || 0,
        loading: liveMigrationsLoading,
      },
      {
        label: 'Pending',
        value: liveMigrations?.filter((lm) => lm?.status?.phase === 'Pending')?.length || 0,
        loading: liveMigrationsLoading,
      },
      {
        label: 'Succeeded',
        value: liveMigrations?.filter((lm) => lm?.status?.phase === 'Succeeded')?.length || 0,
        loading: liveMigrationsLoading,
      },
      {
        label: 'Failed',
        value: liveMigrations?.filter((lm) => lm?.status?.phase === 'Failed')?.length || 0,
        loading: liveMigrationsLoading,
      },
    ]
  }, [liveMigrations, liveMigrationsLoading])

  return (
    <div>
      <TopLinks refreshFn={handleReload} />
      <div className={classes.topCards}>
        <StatCard
          title={
            <div className={classes.cardHeader}>
              <Text variant="subtitle2">Clusters</Text>
              <SeeAllLink route={routes.kubevirtCluster.list.path()} />
            </div>
          }
          data={clusterData}
        />
        <StatCard
          title={
            <div className={classes.cardHeader}>
              <Text variant="subtitle2">Kubevirt Add-on Status</Text>
              <SeeAllLink route={routes.kubevirtCluster.list.path()} />
            </div>
          }
          data={kubevirtAddonData}
        />
        <StatCard
          title={
            <div className={classes.cardHeader}>
              <Text variant="subtitle2">Virtual Machines</Text>
              <SeeAllLink route={routes.kubevirt.overview.path()} />
            </div>
          }
          data={vmData}
          // info="Click for more information on when to use Virtual Machines"
          // infoLink={vmsLink}
        />
      </div>
      <div className={classes.middleCards}>
        <StatCard
          title={
            <div className={classes.cardHeader}>
              <Text variant="subtitle2">Nodes</Text>
              <SeeAllLink route={routes.kubevirtNodes.list.path()} />
            </div>
          }
          data={nodeData}
        />
        <StatCard
          title={
            <div className={classes.cardHeader}>
              <Text variant="subtitle2">Storage</Text>
              <SeeAllLink route={routes.kubevirtStorage.persistentVolumeClaims.list.path()} />
            </div>
          }
          data={storageData}
        />
        <StatCard
          title={
            <div className={classes.cardHeader}>
              <Text variant="subtitle2">Live Migrations</Text>
              <SeeAllLink route={routes.liveMigrations.overview.path()} />
            </div>
          }
          data={liveMigrationData}
        />
      </div>
      <div className={classes.bottomCards}>
        <KubevirtAlarmsList alarms={alarms} loadingAlarms={alertsLoading} />
        <EntityEventsPage
          entity={eventEntities}
          loading={vmsLoading || vmisLoading}
          entityClusterIds={params?.clusterId}
          noMargin
          stretchHeight
        />
      </div>
    </div>
  )
}
